/* Import the Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Edu+SA+Beginner&family=Montserrat:wght@200&display=swap");

.animate {
	text-transform: uppercase;
	background-image: linear-gradient(
		-225deg,
		#231557 0%,
		#44107a 29%,
		#ff1361 67%,
		#fff800 100%
	);
	background-size: auto auto;
	background-clip: border-box;
	background-size: 200% auto;
	color: #fff;
	background-clip: text;
	text-fill-color: transparent;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	animation: textclip 2s linear infinite;
}
@keyframes textclip {
	to {
		background-position: 200% center;
	}
}
.pop-outin {
	animation: 2s anim-popoutin ease infinite;
}

nav {
	font-size: 3em;
}
html {
	scroll-behavior: smooth;
}

/* .animated-text span {
	color: purple;
	transition: color 0.3s ease-in-out;
} */
