.contact-container {
	background-color: #f8f9fa;
	color: #343a40;
	padding: 20px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
/* .text-dark:hover,
.text-dark:focus {
	color: #6610f2;
} */
