/* MyNavBar.css */
.nav-link {
  font-size: 0.5em;
  color: black;
  transition: color 0.3s;
}

.nav-link:hover {
  color: purple;
}
